<template>
  <card
    ref="card"
    actions
    search
    block
    :title="$tc('app.log', 2)"
    :loading="loading"
    @reload="fetch"
  >
    <template #body>
      <data-table
        :data="logs"
        :pages="pages"
        :columns="[
          { key: 'created_at', label: 'date', filters: ['date|DD/MM/YYYY'] },
          'description',
        ]"
        searchable
        buttons
        @search="search"
        @change-page="fetch"
      >
      </data-table>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Card from '@/components/rebranding/Card.vue';
import DataTable from '@/components/rebranding/DataTable.vue';

export default {
  name: 'LogsCard',
  components: {
    Card,
    DataTable,
  },
  data() {
    return {
      loading: true,
      q: '',
      page: 1,
      pages: 1,
      logs: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchLogs', { page, q: this.q })
        .then((response) => {
          this.logs = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    search(q) {
      this.q = q;
      this.fetch(1);
    },
  },
};
</script>
