<template>
  <div class="progress m-t-10">
    <!-- <div
      class="progress-bar"
      role="progressbar"
      :class="{ 'bg-success': progress < 100, 'bg-danger': progress >= 100 }"
      :style="`width: ${progress}%`"
    >
      {{ current }}/{{ max }}
    </div> -->
    <div
      class="progress-bar"
      role="progressbar"
      :class="'bg-yup'"
      :style="`width: ${progress}%`"
    >
      {{ current }}/{{ max }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    current: { type: Number, default: 0 },
    max: { type: Number, default: 0 },
  },
  data() {
    return {};
  },
  computed: {
    progress() {
      return (this.current / this.max) * 100;
    },
  },
  methods: {},
};
</script>
