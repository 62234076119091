<template>
  <div class="content block-el">
    <div v-if="loading" class="static qt-block-ui" style="padding: 120px" />
    <template v-else>
      <!-- <PageHeader :title="`${plan.service} - ${plan.name}`" /> -->
      <PageHeader title="Detalhes do Plano" />
      <div class="page-content container-fluid billing-detail-cards">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body plans-card">
                <div class="plan-desc">
                  <b class="title">{{ $t('your_plan') }}</b>
                  <span class="my-plan">{{plan.name}}</span>
                  <p class="plan-value">
                    R$<span>1.189,00</span>/mês<br /><small>R$ 588/ano</small>
                  </p>
                  <p v-html="$t('billing.plan.about', { name: plan.name })"></p>
                </div>
                <div class="plan-info">
                  <span>{{ $t('properties.infos') }}</span>
                  <div class="plan-items" v-for="(price, feature) in plan.pivot.pricing"
                    :key="feature">
                    <p><b>{{ $tc(`app.${feature}`) }}</b><span>{{
                      price | currency
                    }}/{{$tc('app.month')}}</span></p>
                  </div>
                  <!-- <p><b>{{$t('app.fee')}}</b><span>R$ 88 /mês</span></p>
                  <p><b>{{$tc('app.message', 2)}}</b><span>R$ 88 /mês</span></p>
                  <p><b>{{$tc('app.agent', 2)}}</b><span>R$ 88 /mês</span></p>
                  <p><b>{{$t('app.storage')}}</b><span>R$ 88 /mês</span></p> -->
                </div>
                <div class="opt-btn">
                  <button class="btn btn-yup-purple-outline border-0" @click="$bvModal.show('bv-modal-cancel-plan')">
                    {{ $t('admin.plan.cancel') }}
                  </button>
                  <router-link to="/billing/upgrade" class="btn btn-yup-purple">
                    {{ $t('properties.btn-change-plan') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <card :title="$tc('billing.plan.your_plan')">
              <template #body>
                <span
                  v-html="$t('billing.plan.about', { name: plan.name })"
                ></span>
                <h4 class="m-t-20">{{ $tc('app.price_estimate') }}</h4>
                <span class="h3 text-danger">{{
                  plan.pivot.pricing.fee | currency
                }}</span>
              </template>
            </card>
          </div> -->
          <div class="col-md-3">
            <card class="card-status" :title="$tc('app.status')">
              <template #body>
                <div
                  v-for="(value, feature) in plan.pivot.features"
                  :key="feature"
                  class="row m-t-10"
                >
                  <div class="col-12">
                    <span class="status-title">{{ $tc(`app.${feature}`, 2) }}</span>
                    <progress-bar
                      :current="plan.pivot.usage[feature]"
                      :max="value"
                      :class="'rounded-0'"
                    />
                  </div>
                </div>
              </template>
            </card>
          </div>
          <div class="col-md">
            <logs-card />
          </div>
        </div>
        <!-- <div class="col-md">
          <card :title="$tc('billing.plan.info')">
            <template #body>
              <span
                v-for="(price, feature) in plan.pivot.pricing"
                :key="feature"
              >
                <h3 class="m-t-20">{{ $tc(`app.${feature}`) }}</h3>
                <span class="h3 text-danger">{{
                  price | currency
                }}</span>
              </span>
            </template>
          </card>
        </div> -->
      </div>
    </template>
    <b-modal id="bv-modal-cancel-plan" size="full" hide-footer>
       <template #modal-title>
         Using <code>$bvModal</code> Methods
       </template>
       <div class="d-block text-center">
        <h3>Hello From This Modal!</h3>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-cancel-plan')">Close Me</b-button>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import LogsCard from '@/components/rebranding/cards/LogsCard.vue';
import ProgressBar from '@/components/rebranding/ProgressBar.vue';

export default {
  name: 'BillingPlan',
  components: {
    PageHeader,
    Card,
    LogsCard,
    ProgressBar,
  },
  data() {
    return {
      loading: true,
      plan: {},
      logs: [],
    };
  },
  computed: {},
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const planId = this.$route.params.planId;
      this.loading = true;
      this.$store.dispatch('fetchPlan', planId).then((response) => {
        this.plan = response;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* Rebranding */
.billing-detail-cards {
  .card {
    height: 100%;
  }
}

.plans-card,
.plan-info {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.plans-card .title, ::v-deep .card-status .card-title, .status-title {
  font-size: 1.65rem;
  font-weight: 400;
  color: var(--foreground);
  width: 100%;
  margin-bottom: 2rem;
}

.my-plan {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.plan-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-info {
  gap: 1.35rem;
}

.plan-info p {
  display: flex;
}

.plan-info p span {
  margin-left: auto;
}

.plans-card button {
  width: fit-content;
  margin: auto auto 0 auto;
}

.plan-value span {
  font-size: 1.75rem;
  font-weight: bold;
  position: relative;
  top: 18px;
}

.plan-value small {
  margin-left: 20px;
  position: relative;
  top: 10px;
}

.plan-value ~ p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.plan-info > span:first-of-type {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: -0.5rem;
}

.opt-btn {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Status Card */
::v-deep .card-status .card-body {
  gap: 2rem;
}

::v-deep .card-status .progress {
  height: 1.7rem;
}

::v-deep .card-status .card-title, .status-title {
  margin-bottom: 0;
}

::v-deep .card-status .card-body {
  display: flex;
  flex-direction: column;
}

::v-deep #bv-modal-cancel-plan ~ .modal-backdrop {
  background-color: var(--background-2);
}

::v-deep {
  .modal-dialog.modal-full {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}
</style>
